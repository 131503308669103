import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import {
  Button, NumberInput, DatePicker, TextInput, SimpleSelect,
} from '@doit/pcnt-react-ui-library';
import moment from 'moment';
import { addProductToPayBill, getPaymentBillDetail, getPaymentBarcodeDetail } from '../api/onboardingService';
import i18n from '../common/i18n';
import '../assets/css/payServices.css';
import { loanRedirect } from './LoanRequestRedirectService';
import { getResponseErrorOrDefault } from '../utils/functionsUtil';

/* eslint arrow-body-style:off */
const PayServicesDynamicForm = ({
  loan,
  selectedServiceCompany,
  paymentBillDetailList,
  serviceSelected,
  serviceFormFilled,
  setErrorMessage,
  setServiceFormFilled,
  setPaymentBillDetailList,
}) => {
  const info = i18n.PRODUCT_LOAD.PAY_SERVICE;
  const [loading, setLoading] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const setValueForm = async (field, value) => {
    const fieldValue = {};
    fieldValue[field] = value;
    if (Object.keys(serviceFormFilled).length > 0) {
      setServiceFormFilled({ ...serviceFormFilled, ...fieldValue });
    } else {
      setServiceFormFilled(fieldValue);
    }
  };

  const isValidForm = () => {
    const keys = Object.keys(serviceFormFilled);
    let lenghtServiceFormFilled = 0;
    keys.forEach((element) => {
      const value = serviceFormFilled[element];
      if (value && (value.toString()).trim() !== '') {
        lenghtServiceFormFilled += 1;
      }
    });
    return (serviceSelected?.form?.length === lenghtServiceFormFilled && serviceSelected.paymentType !== 'CLR')
      || (serviceFormFilled.barcode && (serviceFormFilled.barcode.toString()).trim() !== '');
  };

  const refreshLoanRequest = () => {
    loanRedirect(loan, navigate, queryParams.toString());
  };

  const addProductToPayCabBill = async () => {
    try {
      setLoading(true);
      const data = {
        companyCode: serviceSelected.companyCode,
        companyName: selectedServiceCompany.name,
        paymentModeId: serviceSelected.id,
        paymentType: serviceSelected.paymentType,
        form: serviceFormFilled,
      };
      await addProductToPayBill(loan.hashKey, data);
      refreshLoanRequest();
    } catch (error) {
      console.log('error');
      console.log(error);
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const submitServiceForm = async () => {
    try {
      setLoading(true);
      const serviceFormFilledTemp = { ...serviceFormFilled };
      if (serviceSelected.paymentType === 'CAB') {
        addProductToPayCabBill();
      } else {
        let res;
        if (serviceSelected.paymentType === 'CLR') {
          res = await getPaymentBarcodeDetail(loan.hashKey, serviceSelected.companyCode, serviceFormFilledTemp.barcode);
        } else if (serviceSelected.paymentType === 'CSF' || serviceSelected.paymentType === 'SFM') {
          await serviceSelected.form.forEach(async (input) => {
            if (input.dataType === 'FEC') {
              const filledDate = serviceFormFilledTemp[input.field];
              serviceFormFilledTemp[input.field] = moment(filledDate).format('YYYY-MM-DD');
            }
          });
          res = await getPaymentBillDetail(
            loan.hashKey, serviceSelected.companyCode, serviceSelected.id, serviceFormFilledTemp,
          );
        }
        const paymentBillDetailListTemp = res.data.filter((bill) => !bill.expired);
        await setPaymentBillDetailList(paymentBillDetailListTemp);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      { /* Muestra el formulario del servicio seleccionado */}
      {
        serviceSelected && !paymentBillDetailList && (
          <>
            <Box mt={2} />
            <Grid container spacing={3} className="flexGrid">
              {serviceSelected.paymentType === 'CLR' && (
                <Grid item key="barcode" xs={12}>
                  <Typography className="services-input-label capitalize">
                    {info.barcode}
                  </Typography>
                  <NumberInput
                    onChange={(event) => setValueForm('barcode', event.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
              {serviceSelected.form && serviceSelected.form.length > 0 && (
                serviceSelected.form.map((input) => (
                  <Grid item key={input.field} xs={12} sm={6}>
                    {input.component === 'TEXT_FIELD' && (
                      <>
                        <Typography className="services-input-label capitalize">
                          {input.label.toLowerCase()}
                        </Typography>
                        {input.dataType === 'FEC' ? (
                          <DatePicker
                            onChange={(value) => setValueForm(input.field, value)}
                            value={serviceFormFilled[input.field] || null}
                          />
                        ) : (
                          input.dataType === 'IMP' ? (
                            <NumberInput
                              onChange={(event) => setValueForm(input.field, event.target.value)}
                              fullWidth
                              inputProps={{ maxLength: input.length || '' }}
                            />
                          ) : (
                            <TextInput
                              onChange={(event) => setValueForm(input.field, event.target.value)}
                              fullWidth
                              inputProps={{ maxLength: input.length || '' }}
                            />
                          )
                        )}
                      </>
                    )}
                    {input.component === 'HIDDEN_TEXT_FIELD' && (
                      <>
                        <Typography className="services-input-label capitalize">
                          {input.label.toLowerCase()}
                        </Typography>
                        <TextInput
                          inputProps={{
                            type: 'password',
                          }}
                          onChange={(event) => setValueForm(input.field, event.target.value)}
                          fullWidth
                        />
                      </>
                    )}
                    {input.component === 'COMBO_BOX_ESTATICO' && (
                      <>
                        <Typography className="services-input-label capitalize">
                          {input.label.toLowerCase()}
                        </Typography>
                        <SimpleSelect
                          fullWidth
                          onChange={(value) => setValueForm(input.field, value)}
                          options={Object.keys(input.values).map((key) => {
                            return {
                              label: input.values[key],
                              value: key,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                ))
              )}
            </Grid>
            <Box mt={4} />
            <Button
              variant="secondary"
              className="app-bar-btn"
              onClick={submitServiceForm}
              disabled={!isValidForm()}
              loading={loading}
              fullWidth
            >
              {info.btnContinue}
            </Button>
          </>
        )
      }
    </>
  );
};

PayServicesDynamicForm.propTypes = {
  loan: PropTypes.shape(),
  selectedServiceCompany: PropTypes.shape(),
  paymentBillDetailList: PropTypes.shape(),
  serviceSelected: PropTypes.shape(),
  serviceFormFilled: PropTypes.shape(),
  setErrorMessage: PropTypes.func,
  setServiceFormFilled: PropTypes.func,
  setPaymentBillDetailList: PropTypes.func,
};

PayServicesDynamicForm.defaultProps = {
  loan: undefined,
  selectedServiceCompany: undefined,
  paymentBillDetailList: undefined,
  serviceSelected: undefined,
  serviceFormFilled: undefined,
  setErrorMessage: undefined,
  setServiceFormFilled: undefined,
  setPaymentBillDetailList: undefined,
};

export default PayServicesDynamicForm;
